export const paymentCancel = {
    /* 결제 취소 요청 성공 */
    payment_cancel_request_complete_title: '예약 취소가 완료되었습니다.',
    payment_cancel_request_complete_text:
        '취소 확정 시, 골라가 알림톡으로 안내해드릴 예정이며 취소내역에서도 확인하실 수 있습니다.',
    payment_cancel_request_complete_sub_text:
        '알림톡을 못 받으셨거나 취소를 확인하실 수 없다면, 고객센터(1600-1552)로 문의주시기 바랍니다.',

    /* 결제 취소 요청 실패 */
    payment_cancel_request_fault_title: '예약 취소를 완료하지 못했습니다.',
    payment_cancel_request_fault_text:
        '잘못된 경로로 접근되어 요청에 실패하였습니다. \n 이용에 불편을 드려 죄송합니다.',

    payment_cancel_request_fault_sub_text:
        '다시 취소하여도 계속 같은 오류가 발생하면, 고객센터(1600-1552)로 문의하여 주시기 바랍니다.',
    payment_fail_request_sub_text:
        '계속 같은 오류가 발생하면 골라가 채널톡으로 문의하여 주시기 바랍니다.'
};
