import React from 'react';
import Header from '../Header';
import TitleArea from '../common/TitleArea';
import './mypage.scss';

function Company({title}) {
    const companyList = [
        {
            title: '주소',
            contents: '서울 용산구 한강대로98길 3, KCCIT타워 8층, 10층'
        },
        {
            title: '사업자 등록번호',
            contents: '106-86-38206'
        },
        {
            title: '대표이사',
            contents: '박준태'
        },
        {
            title: '골라가 고객센터',
            contents: '채널톡 문의(https://golaga.golf/cs)'
        },
        {
            title: 'FAX',
            contents: '02-795-4087'
        }
    ];

    return (
        <>
            <Header close={true} />
            <div id="contents">
                <div className="contentsArea">
                    <div className="inner">
                        <TitleArea pageTitle={title} />
                        <div className="companyInfo">
                            {companyList.map((item, idx) => {
                                return (
                                    <section key={idx}>
                                        <h2>{item.title}</h2>
                                        <p>{item.contents}</p>
                                    </section>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Company;
